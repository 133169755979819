import { i18n } from "@/plugins/i18n";
export default [
  {
    text: i18n.t("collar_type.white"),
    value: "WHITE"
  },
  {
    text: i18n.t("collar_type.blue"),
    value: "BLUE"
  },
  {
    text: i18n.t("collar_type.gray"),
    value: "GRAY"
  }
];
