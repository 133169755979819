<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :items="collarTypes"    
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
    item-text="text"
    item-value="value"
  >
    <template v-if="$attrs.hasOwnProperty('multiple')" v-slot:selection="{ item, index }">
      <span v-if="index === 0">
        {{ index === 0 ? `${selected.length} ${$t("global.selected", [$t("global.collar_type")])}` : "" }}
      </span>
    </template>
    <template v-else v-slot:selection="{ item, index }">
      {{ item.text }}
    </template>

    <template slot="item" slot-scope="data">
      <template>
        <v-list-item-avatar size="30" color="primary">
          <span class="white--text font-weight-bold">{{ data.item.text.substring(0, 1).toUpperCase() }}</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.text" />
        </v-list-item-content>
      </template>
    </template>
  </v-select>
</template>

<script>
  import collarTypes from "./collarTypes";

  export default {
    name: "CollarTypesPicker",
    props: {
      value: [String, Object, Array]
    },
    data: vm => ({
      collarTypes,
      selected: null
    }),

    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    }
  };
</script>

<style scoped></style>
